<template>
  <b-row class="lists page-my-cards">
    <b-col>
      <PageHeader :title="$t('general.my-cards.title')" />

      <div class="post-list">
        <div class="page-my-cards__tabs">
          <CRadio
            v-model="selectedTab"
            v-for="(item, index) in tabs"
            :key="`${index + '-tab'}`"
            :name="item.name"
            :value="item"
            group-name="user-tab"
            class="user-tab"
          >
            <template #default="{ isActive, value: tab }">
              <div
                class="user-tab__item"
                :class="{ 'user-tab__item--active': isActive }"
              >
                <span class="user-tab__font"> {{ tab.content }} </span>
              </div>
            </template>
          </CRadio>
        </div>
      </div>

      <b-row
        v-if="selectedTab.id === cardsCallbackType.cards"
        class="px-3 mt-3 d-block"
      >
        <CButton variant="primary" block @click.prevent="openAddCardPopup">
          {{ $t("general.my-cards.addPaymentCardButton") }}
        </CButton>
      </b-row>

      <div class="post-list__posts">
        <InfinityScroll
          v-if="cardList.list && cardList.list.length"
          :items="cardList.list"
          :has-more="cardList.hasMoreCards"
          @rich-end="cardList.loadMore"
        >
          <template #default="{ item: card }">
            <PaymentCard
              :card="card"
              v-if="selectedTab.id === cardsCallbackType.cards"
            />
            <TransactionCard :card="card" v-else />
          </template>
        </InfinityScroll>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

import PageHeader from "@/layout/PageHeader.vue";
import CRadio from "@/features/ui/CRadio.vue";
import InfinityScroll from "@/features/components/InfinityScroll.vue";

import { mapActions, mapGetters } from "vuex";
import CButton from "@/features/ui/СButton.vue";
import PaymentCard from "@/features/components/PaymentCard.vue";
import TransactionCard from "@/features/components/TransactionCard.vue";

const cardsCallbackType = {
  cards: "cards",
  history: "history",
};
export default {
  name: "PageMyCards",
  components: {
    TransactionCard,
    PaymentCard,
    CButton,
    InfinityScroll,
    CRadio,
    PageHeader,
  },
  mixins: [waitRequest, validationError],
  data() {
    return {
      lists: [],
      cardsCallbackType,
      selectedTab: {
        id: cardsCallbackType.cards,
        content: this.$t("general.my-cards.my-cards"),
      },

      loadCardsCallback: {
        [cardsCallbackType.cards]: this.setCurrentCards,
        [cardsCallbackType.payments]: this.updateCurrentCards,
      },
    };
  },
  mounted() {
    this.initialLoadCards();
  },
  computed: {
    ...mapGetters({
      cards: "payments/cards",
    }),
    cardList() {
      if (this.selectedTab.id === this.cardsCallbackType.cards) {
        const cards =
          this.cards?.cards?.methods?.toSorted((a, b) => a.id - b.id) || [];
        // cards
        return {
          list: cards,
          hasMoreCards: null,
          loadMore: () => {},
        };
      }
      // payments
      return {
        list: this.paymentsList,
        hasMoreCards: Boolean(this.cards?.next_page_url),
        loadMore: () => this.loadPayments({ page: this.cards?.page + 1 }),
      };
    },

    paymentsList() {
      return this.cards?.history?.data?.map(item => {
        const title = item.info?.payment_method?.title || ''
        return {
          ...item,
          title,
        }
      }) || []
    },

    tabs() {
      return [
        {
          id: cardsCallbackType.cards,
          content: this.$t("general.my-cards.my-cards"),
        },
        {
          id: cardsCallbackType.history,
          content: this.$t("general.my-cards.my-payments"),
        },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchPaymentHistory: "payments/fetchPaymentHistory",
      fetchPaymentCards: "payments/fetchPaymentCards",
    }),

    createQueryConfig(config) {
      return {
        page: this.cards.page,
        limit: this.cards.per_page,

        ...config,
      };
    },

    openAddCardPopup() {
      this.$popup.open("AddCardPopup");
    },

    loadCards() {
      if (this.requestInProgress) return;

      return this.waitRequest(() => {
        return this.fetchPaymentCards().catch(this.checkErrors);
      });
    },

    loadPayments(config) {
      const queryConfig = this.createQueryConfig(config);

      this.waitRequest(() => {
        return this.fetchPaymentHistory(queryConfig).catch(this.checkErrors);
      });
    },

    async initialLoadCards() {
      this.$showSpinner();

      this.loadCards().then(() => {
        return this.fetchPaymentHistory({ page: 1 }).catch(this.checkErrors);
      }).finally(() => {
        this.$hideSpinner();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.lists {
  a {
    text-decoration: none;
    color: var(--dark);

    &:hover {
      color: var(--dark);
      text-decoration: none;
      background: var(--light);
    }
  }
}

.page-my-cards {
  &__header {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-end;
  }

  &__search {
    margin-top: em(16);
  }

  &__tabs {
    display: flex;
    flex-wrap: nowrap;
  }

  &__group {
    margin: em(22) em(17);
  }
}

.collections-header-button {
  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-tab {
  flex: 1;
  text-align: center;

  &__item {
    padding-top: em(15);
    padding-bottom: em(17);
    border-bottom: 1px solid $app-gray-7;

    &--active {
      padding-bottom: em(16);
      border-bottom: 2px solid $app-blue;
    }
  }

  &__font {
    color: $app-black;
    text-align: center;
    font-family: $font-default;
    font-size: em(14);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.post-list {
  margin-right: -15px;
  margin-left: -15px;

  &__posts {
    margin-top: 1px;
  }
}
</style>
