<template>
  <div class="payment-card">
    <div class="payment-card__header">
      <div class="payment-card__card">
        <div class="payment-card__image-wrapper">
          <img
            :src="cardImage"
            :alt="`brand logo ${brand}`"
            class="payment-card__image"
          />
        </div>

        <div class="payment-card__description">
          <div class="payment-card__title">
            <span
              v-if="brand"
              class="payment-card__font payment-card__font--title"
            >
              {{ brand }}
            </span>

            <span
              v-if="curdNumber"
              class="payment-card__font payment-card__font--number"
            >
              {{ curdNumber }}
            </span>
          </div>

          <UiBadge v-if="address" class="payment-card__badge">
            {{ address }}
          </UiBadge>
        </div>
      </div>

      <div class="payment-card__info">
        <tippy
          interactive
          placement="bottom-center"
          distant="7"
          trigger="mouseenter"
        >
          <template #trigger>
            <CIcon
              name="settings"
              path="payment-card"
              class="payment-card__icon"
            />
          </template>

          <div class="payment-card__tooltip">
            <button
              type="button"
              @click.prevent="setDefault"
              class="payment-card__button"
            >
              <span
                class="payment-card__button__font payment-card__button__font--tooltip"
              >
                {{ $t("general.my-cards.setDefault") }}
              </span>
            </button>

            <button
              type="button"
              @click.prevent="removeCard"
              class="payment-card__button"
            >
              <span
                class="payment-card__button__font payment-card__button__font--tooltip"
              >
                {{ $t("general.my-cards.deleteCurd") }}
              </span>
            </button>
          </div>
        </tippy>
      </div>
    </div>

    <div class="payment-card__button">
      <button @click="updateCardInfo" class="card-button">
        <span class="card-button__font">
          {{ $t("general.my-cards.updateCard") }}</span
        >
      </button>
    </div>

    <div class="payment-card__footer">
      <span class="payment-card__font payment-card__font--toggle">
        {{ $t("general.my-cards.defaultMethod") }}
      </span>

      <CButtonToggle
        class="payment-card__toggle"
        :is-active="isDefaultCard"
        @click="setDefault"
      />
    </div>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";
import UiBadge from "@/features/ui/common/UiBadge.vue";
import CButtonToggle from "@/features/ui/CButtonToggle.vue";
import { mapActions } from "vuex";
import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import { paymentMethods } from "@/config/paymentMethods";

export default {
  name: "PaymentCard",
  components: { CButtonToggle, UiBadge, CIcon },
  mixins: [waitRequest, validationError],
  props: {
    card: {
      type: Object,
      required: true,
    },
  },
  computed: {
    cardImage() {
      switch (this.brand) {
        case paymentMethods.Visa:
        case paymentMethods.VisaDebit:
          return "/img/payments/visa-round.webp";
        case paymentMethods.MasterCard:
        case paymentMethods.MasterCardDebit:
          return "/img/payments/mastercard-round.webp";
        case paymentMethods.Discover:
          return "/img/payments/discover-round.webp";
        case paymentMethods.AMEX:
          return "/img/payments/mastercard-round.webp";
        default:
          return "/img/payments/default-round.webp";
      }
    },
    address() {
      return this.card?.address || "";
    },
    brand() {
      return this.card?.brand || "";
    },
    isWallet() {
      return Boolean( this.card?.title?.replaceAll("*", ""))
    },
    curdNumber() {
      const text = this.card?.title?.replaceAll("*", "");
      return text ? `...${text}` : "WALLET";
    },
    isDefaultCard() {
      return this.card?.main || false;
    },
  },
  methods: {
    ...mapActions({
      setDefaultCards: "payments/setDefaultCards",
      deleteCard: "payments/deleteCard",
    }),

    updateCardInfo() {
      console.log("updateCardInfo");
      this.$popup.open("AddCardPopup", { card: this.card });
    },
    setDefault() {
      if (this.requestInProgress) return;

      this.waitRequest(() => {
        return this.setDefaultCards(this.card.id).catch(this.checkErrors);
      });
    },
    removeCard() {
      if (this.requestInProgress) return;

      this.waitRequest(() => {
        return this.deleteCard(this.card.id).catch(this.checkErrors);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.payment-card {
  border: 1.5px solid $app-gray-25;
  border-radius: 10px;
  width: 100%;
  height: 164px;
  padding: 16px 15px;
  margin-top: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__card {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__image-wrapper {
  }

  &__image {
    width: 42px;
    height: 42px;
    border-radius: 50%;
  }

  &__description {
    display: flex;
    flex-direction: column;
    margin-left: em(11);
  }

  &__title {
  }

  &__badge {
    margin-top: em(6);
  }

  &__font {
    &--title {
      font-weight: 500;
      font-size: 14px;
      color: $app-black;
      text-transform: uppercase;
    }

    &--number {
      font-weight: 400;
      font-size: 14px;
      color: $app-gray-18;
    }

    &--toggle {
      font-weight: 400;
      font-size: 12px;
      color: $app-gray-18;
    }
  }

  &__badge {
  }

  &__info {
  }

  &__icon {
    width: 19px;
    height: 19px;
  }

  &__button {
    margin-top: em(16);
  }

  &__footer {
    display: flex;
    align-items: center;
    margin-top: em(14);
  }

  &__toggle {
    margin-left: em(10);
    font-size: 50%; // change toggle default size
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    color: $black;
    text-decoration: none;
    justify-content: flex-start;

    &:not(:first-child) {
      margin-top: em(15);
    }

    &:active {
      transform: none;
      filter: none;
    }
  }
}

.card-button {
  padding: em(14) em(16);
  border: 1.5px solid $app-gray-25;
  border-radius: 100px;
  justify-content: flex-start;

  &__font {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #308dff;
  }
}
</style>
